import {
  IPlatformData,
  withCompController,
} from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  ISiteButtonMapperProps,
  ISiteButtonControllerProps,
  ISiteButtonProps,
  ISiteButtonStateValues,
} from '../SiteButton.types';

const useComponentProps = ({
  mapperProps,
  stateValues,
}: IPlatformData<
  ISiteButtonMapperProps,
  ISiteButtonControllerProps,
  ISiteButtonStateValues
>): ISiteButtonProps => {
  const {
    shouldReportAnalyticsElementsClicks,
    compId,
    language,
    pagesMap,
    ...restMapperProps
  } = mapperProps;

  const reportBiOnClick: ISiteButtonProps['onClick'] = event => {
    const { fullNameCompType, label, link, isDisabled } = restMapperProps;
    const { reportBi, pageId } = stateValues;

    tryReportAnalyticsClicksBi(reportBi, {
      link,
      language,
      shouldReportAnalyticsElementsClicks,
      elementTitle: label,
      elementType: fullNameCompType,
      pagesMetadata: { pagesMap, pageId },
      elementGroup: AnalyticsClicksGroups.Button,
      details: { isDisabled: isDisabled ?? false },
      element_id: compId ?? event.currentTarget.id,
    });
  };

  return {
    ...restMapperProps,
    reportBiOnClick,
  };
};

export default withCompController(useComponentProps);
