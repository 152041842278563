import React from 'react';
import classNames from 'classnames';
import type { TranslationGetter } from '@wix/editor-elements-types/thunderbolt';
import {
  customCssClasses,
  useVideoAPI,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import type { MediaContainerVideoAPI } from '@wix/editor-elements-types/components';
import { MediaContainerCompProps } from '../MediaContainer.types';
import {
  ARIA_LABEL_DEFAULT,
  ARIA_LABEL_KEY,
  ARIA_LABEL_NAMESPACE,
} from '../../../Container/viewer/shared/constants';
import repeaterSemanticClassNames from '../../../Repeater/Repeater.semanticClassNames';
import MediaContainerContent from './MediaContainerContent';
import styles from './styles/MediaContainer.scss';

// TODO: replace with import when MediaContainer will be moved to editor-elements-library
const stripSemanticClassNames = {
  root: 'column-strip',
  column: 'column-strip__column',
} as const;

const getAriaLabel = (translate: TranslationGetter | undefined) => {
  return translate
    ? translate(ARIA_LABEL_NAMESPACE, ARIA_LABEL_KEY, ARIA_LABEL_DEFAULT)
    : ARIA_LABEL_DEFAULT;
};

const MediaContainer: React.ForwardRefRenderFunction<
  MediaContainerVideoAPI,
  MediaContainerCompProps
> = (props: MediaContainerCompProps, compRef) => {
  const {
    id,
    className,
    customClassNames = [],
    children,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
    shouldAddTabIndex0: shouldAddA11yAttributes,
    hasPlatformClickHandler,
    translate,
    fillLayers,
    onStop,
    isRepeaterItem,
    columnOverrides,
  } = props;

  const getRootSemanticClasses = () => {
    if (isRepeaterItem) {
      return repeaterSemanticClassNames.repeaterItem;
    }
    if (columnOverrides) {
      return stripSemanticClassNames.column;
    }
    return '';
  };

  const rootClassName = classNames(
    className,
    styles.mediaContainer,
    customCssClasses(getRootSemanticClasses(), ...customClassNames),
    {
      [styles.clickable]: hasPlatformClickHandler,
    },
  );

  const a11yAttributes = shouldAddA11yAttributes
    ? { tabindex: 0, role: 'region', 'aria-label': getAriaLabel(translate) }
    : {};

  const hasVideo = !!fillLayers.video;
  const videoRef = useVideoAPI(compRef, hasVideo, onStop);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={rootClassName}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...a11yAttributes}
    >
      <MediaContainerContent {...props} videoRef={videoRef}>
        {children}
      </MediaContainerContent>
    </div>
  );
};

export default React.forwardRef(MediaContainer);
